<script lang="ts" setup>
const localePath = useLocalePath();
</script>

<template>
    <div class="py-6 bg-gray-400 px-8">
        <div class="container flex flex-col text-center lg:flex-row lg:justify-between lg:items-center">
            <div
                class="text-white mb-5 lg:mb-0"
                v-html="$t('copyrightShort', { currentYear: new Date().getFullYear() })"
            />
            <NuxtLink
                class="!text-white text-sm hover:underline mb-6 lg:mb-0"
                :to="localePath($t('contactLink'))"
            >
                {{ $t('contact') }}
            </NuxtLink>
        </div>
    </div>
</template>
